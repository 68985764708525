import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ExpandablePanel } from "@entur/expand";
import { Link, ListItem, NumberedList } from '@entur/typography';
import './guides.scss';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "search-guide"
    }}>{`Search Guide`}</h1>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <NumberedList className="offers-guides-toc" mdxType="NumberedList">
    <ListItem mdxType="ListItem">
        <Link href="#how-to-use-the-offers-api" mdxType="Link">How to use the Offers API</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#search-by-trip-pattern" mdxType="Link">Search by trip pattern</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#search-by-stop-places" mdxType="Link">Search by stop places</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#search-by-authority" mdxType="Link">Search by authority</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#search-by-zones" mdxType="Link">Search by zones</Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#search-for-personalised-offers" mdxType="Link">Search for personalised offers</Link>
        <NumberedList mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#campaign-codes" mdxType="Link">Campaign codes</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#personalisation-programs" mdxType="Link">Personalisation programs</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#personnel-tickets" mdxType="Link">Personnel tickets</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#discounted-offers" mdxType="Link">Discounted offers</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#offline-clients" mdxType="Link">Offline clients</Link>
    </ListItem>
    </NumberedList>
    <h2 {...{
      "id": "how-to-use-the-offers-api"
    }}>{`How to use the Offers API`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Offers API provides REST-endpoints for generating offers that in turn can be used to create tickets, granting access to public transport services.
The most typical use cases starts by searching for a specific trip or an area of travel,
using the `}<a parentName="p" {...{
        "href": "/pages-journeyplanner-journeyplanner"
      }}>{`Journey Planner API`}</a>{` to find the necessary information to create Offers requests.
Offers uses the definition of products defined in the `}<a parentName="p" {...{
        "href": "/pages-products-docs-intro"
      }}>{`Products API`}</a>{` to calculate prices and evaluate valid products for the requested journey.
For a more detailed guide on how to complete a sale, see the `}<a parentName="p" {...{
        "href": "/pages-sales-guides"
      }}>{`Sales guide`}</a>{`.`}</p>
    <h2 {...{
      "id": "search-guide-search-overview"
    }}>{`Search Guide {#search-overview}`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a></p>
    <p>{`Offers have four endpoints used to search for offers by different means:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#search-by-trip-pattern"
        }}>{`Trip pattern`}</a>{` when a specific departure is known.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#search-by-zones"
        }}>{`Zones`}</a>{` when the desired ticket should be valid for a zone or zones, but not a specific departure.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#search-by-stop-places"
        }}>{`Stop places`}</a>{` if the desired ticket should not be associated with any specific departure, but should yield travel rights between the two stop places provided.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#search-by-authority"
        }}>{`Authority`}</a>{` to search for offers from a specific authority. Typically, for products where geography is not a factor when calculating prices.`}</li>
    </ul>
    <p>{`All end points return the same `}<inlineCode parentName="p">{`OfferSummary`}</inlineCode>{` response, providing a concise overview of the offer without the underlying complexity of the product definition.
The offers are valid for 30 minutes, after which time they can no longer be purchased. A response header `}<inlineCode parentName="p">{`Expires`}</inlineCode>{` is provided in the response indicating the time when the offer expires.`}</p>
    <p>{`The products that give a user a right to travel are found under `}<inlineCode parentName="p">{`preassignedProducts`}</inlineCode>{`.
Each PreassignedProduct has an attribute "properties" shared with optionalProducts that contains most of the information needed for a given product.`}</p>
    <h3 {...{
      "id": "search-by-trip-pattern"
    }}>{`Search by trip pattern`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/external/post-offers-v2-search-trip-pattern"
      }}>{`Swagger documentation`}</a></p>
    <p>{`When a trip is found using the `}<a parentName="p" {...{
        "href": "/pages-journeyplanner-journeyplanner"
      }}>{`Journey Planner API`}</a>{`, legs for the request to
the Offers API can be constructed by extracting travelDate, fromStopPlaceId, toStopPlaceId, and serviceJourneyId from the Journey Planner response.`}</p>
    <ExpandablePanel title="Example Journey Planner trip search request" mdxType="ExpandablePanel">
    <pre>{`{
  trip(
    from: {
      place: "NSR:StopPlace:337"
    },
    to: {
      place: "NSR:StopPlace:11"
    },
    modes: {
      transportModes: [{
        transportMode: rail
      }]
    }
    numTripPatterns: 1
  ) {
    tripPatterns {
      legs {
        datedServiceJourney {
          operatingDay
          serviceJourney {
            id
          }
        }
        fromPlace {
          quay {
            stopPlace {
              name
              id
            }
          }
        }
        toPlace {
          quay {
            stopPlace {
              id
            }
          }
        }
      }
    }
  }
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example Journey Planner trip search response" mdxType="ExpandablePanel">
    <pre>{`{
  "data": {
    "trip": {
      "tripPatterns": [
        {
          "legs": [
            {
              "datedServiceJourney": {
                "operatingDay": "2024-04-23",
                "serviceJourney": {
                  "id": "NSB:ServiceJourney:855-LOD_186471-R"
                }
              },
              "fromPlace": {
                "quay": {
                  "stopPlace": {
                    "name": "Oslo S",
                    "id": "NSR:StopPlace:337"
                  }
                }
              },
              "toPlace": {
                "quay": {
                  "stopPlace": {
                    "id": "NSR:StopPlace:11"
                  }
                }
              }
            }
          ]
        }
      ]
    }
  }
}`}</pre>
    </ExpandablePanel>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`travelDate`}</inlineCode>{` is the operatingDay of the DatedServiceJourney representing the specific trip.
Note that the date of embarkation at the requested from stop is not necessarily the same as the `}<inlineCode parentName="li">{`travelDate`}</inlineCode>{`; typically for ServiceJourneys that starts before, and crosses midnight.
`}<inlineCode parentName="li">{`operatingDay`}</inlineCode>{` of the referred `}<inlineCode parentName="li">{`DatedServiceJourney`}</inlineCode>{` will always point at the correct date, and for ServiceJourneys without a DatedServiceJourney,
the date of the first estimated call is typically correct.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`fromStopPlaceId`}</inlineCode>{` is the id of the `}<inlineCode parentName="li">{`StopPlace`}</inlineCode>{` where the leg starts`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`toStopPlaceId`}</inlineCode>{` is the id of the `}<inlineCode parentName="li">{`StopPlace`}</inlineCode>{` where the leg ends`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`serviceJourneyId`}</inlineCode>{` is the id of the `}<inlineCode parentName="li">{`ServiceJourney`}</inlineCode>{` that in combination with `}<inlineCode parentName="li">{`travelDate`}</inlineCode>{` represents the specific trip`}</li>
    </ul>
    <p>{`A request can contain multiple legs if the trip spans multiple means of transport.`}</p>
    <p>{`The endpoint also requires that at least one `}<inlineCode parentName="p">{`Traveller`}</inlineCode>{` is specified.`}</p>
    <h4 {...{
      "id": "examplerequests-to-searchtrip-pattern"
    }}>{`ExampleRequests to search/trip-pattern`}</h4>
    <ExpandablePanel title="Basic Request Trondheim-Bodø" mdxType="ExpandablePanel">
    <pre>
    {`{
  "tripPattern": {
    "legs": [
      {
        "travelDate": "2022-03-16",
        "fromStopPlaceId": "NSR:StopPlace:659",
        "toStopPlaceId": "NSR:StopPlace:507",
        "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
      }
    ]
  },
  "travellers": [
    {
      "userType": "ADULT"
    }
  ]
}`}
    </pre>
    </ExpandablePanel>
    <p>{`If the traveller already owns a valid ticket, the `}<inlineCode parentName="p">{`existingTicket`}</inlineCode>{` attribute can be used to specify the zones the ticket is valid for,
to potentially get offers that can be combined with the existing ticket for a reduced price. When supplying an `}<inlineCode parentName="p">{`existingTicket`}</inlineCode>{`, only one traveller can be specified in the request.`}</p>
    <ExpandablePanel title="Oslo-Gardermoen with 30DayPass valid for Zones RUT:FareZone:4, RUT:FareZone:3, RUT:FareZone:2" mdxType="ExpandablePanel">
    <pre>
    {`{
  "travellers": [
    {
      "id": "UNIQUE_IDENTIFIER",
      "userType": "ADULT"
    }
  ],
  "tripPattern": {
    "legs": [
      {
        "travelDate": "2022-02-24",
        "fromStopPlaceId": "NSR:StopPlace:337",
        "toStopPlaceId": "NSR:StopPlace:269",
        "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
      }
    ]
  },
  "existingTicket": {
      "id": "RUT:PreassignedFareProduct:Ruter30Days",
      "zones": [
          "RUT:FareZone:4",
          "RUT:FareZone:3",
          "RUT:FareZone:2"
      ]
  }
}`}
    </pre>
    </ExpandablePanel>
    <p>{`If more than one traveller is travelling together, the `}<inlineCode parentName="p">{`travellers`}</inlineCode>{` attribute can contain multiple `}<inlineCode parentName="p">{`Traveller`}</inlineCode>{` objects,
representing each traveller with their respective `}<inlineCode parentName="p">{`userType`}</inlineCode>{` and/or `}<inlineCode parentName="p">{`age`}</inlineCode>{`.
For requests with groups of travellers, the `}<inlineCode parentName="p">{`groupConfig`}</inlineCode>{` attribute can be used to specify the number of compartments desired for a group.
This will only apply to products that are sold as group tickets and yields a compartment.`}</p>
    <ExpandablePanel title="Oslo-Bergen 4 travellers in 2 compartments" mdxType="ExpandablePanel">
    <pre>
    {`{
  "travellers": [
    {
      "id": "A",
      "userType": "ADULT",
      "age": 18
    },
    {
      "id": "B",
      "userType": "CHILD"
    },
    {
      "id": "C",
      "userType": "INFANT",
    },
    {
      "id": "D",
      "userType": "CHILD",
      "age": 12
    }
  ],
  "tripPattern": {
    "legs": [
      {
        "travelDate": "2022-02-24",
        "fromStopPlaceId": "NSR:StopPlace:337",
        "toStopPlaceId": "NSR:StopPlace:548",
        "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
      }
    ]
  },
  "groupConfig": {
      "numberOfCompartments": 2
  }
}`}
    </pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "exampleresponses-from-searchtrip-pattern"
    }}>{`ExampleResponses from search/trip-pattern`}</h4>
    <ExpandablePanel title="Basic Request Trondheim-Bodø" mdxType="ExpandablePanel">
    <pre>
    {`{
  "tripPatternId": "9f527bd5-23db-4a05-bb4a-a75fc5451a27",
  "offers": [
    {
      "id": "ea10daf8-3d77-4a01-b9b4-81260ce8eefe",
      "name": "Standard non-refundable",
      "description": "",
      "price": {
        "amount": "639.00",
        "currency": "NOK"
      },
      "geographicalValidity": {
        "serviceJourneys": [
          {
            "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
            "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
          }
        ],
        "pointToPointValidity": {
          "fromPlace": "NSR:StopPlace:659",
          "toPlace": "NSR:StopPlace:507"
        }
      },
      "preassignedProducts": [
        {
          "id": "SJN:PreassignedFareProduct:Standard",
          "version": "SJN:Version:PFP-Standard-2",
          "name": "Standard non-refundable",
          "description": "- Cannot be changed or refunded\\n- You get a standard seat reserved\\n\\n[More info at SJ (in Norwegian)](https://www.sj.no/om-vare-billetter/vare-billettyper)",
          "properties": {
            "isExchangeable": false,
            "isRefundable": false,
            "accommodations": [
              {
                "serviceJourney": {
                  "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
                  "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
                },
                "accommodation": []
              }
            ],
            "reservingRequirements": [
              {
                "serviceJourney": {
                  "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
                  "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
                },
                "seatMapIsAvailable": true
              },
              {
                "serviceJourney": {
                  "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
                  "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
                },
                "reservingRequirement": "RESERVATIONS_COMPULSORY",
                "seatMapIsAvailable": false
              }
            ],
            "userProfileId": "SJN:UserProfile:Adult",
            "userProfileVersion": "SJN:Version:UPAdult-2020-1",
            "organisation": {
              "id": "SJN:Authority:SJN",
              "name": "SJ Nord"
            },
            "fareClasses": [
              "STANDARD_CLASS"
            ]
          },
          "geographicalValidity": {
            "serviceJourneys": [
              {
                "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
                "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
              }
            ],
            "pointToPointValidity": {
              "fromPlace": "NSR:StopPlace:659",
              "toPlace": "NSR:StopPlace:507"
            }
          }
        }
      ],
      "optionalProductsSelectableIds": [
        "keMdoG",
        "cB4AAY",
        "b0f6UI",
        "wC9t6m",
        "eIXy0B",
        "6tfKdb",
        "Utl3ls",
        "5BKCG8"
      ],
      "travellerMapping": [
        {
          "travellerIds": [
            "ad37276d-2a32-4d84-8c2f-08dc30f05cd6"
          ],
          "maxNumberOfTravellers": 1,
          "minNumberOfTravellers": 1,
          "userType": "ADULT"
        }
      ],
      "available": 68
    } ...
  ],
  "optionalProducts": [
    {
      "id": "SJN:SupplementProduct:BikeReservation",
      "version": "SJN:Version:SUP-BikeReservation-1",
      "selectableId": "keMdoG",
      "name": "Bicycle",
      "description": "There are reserved places for bicycles on the longer regional trains.",
      "price": {
        "amount": "215.00",
        "currency": "NOK"
      },
      "properties": {
        "accommodations": [
          {
            "serviceJourney": {
              "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
              "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
            },
            "accommodation": []
          }
        ],
        "baggageTypes": [
          "BICYCLE"
        ],
        "reservingRequirements": [
          {
            "serviceJourney": {
              "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
              "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
            },
            "reservingRequirement": "RESERVATIONS_COMPULSORY",
            "seatMapIsAvailable": false
          }
        ],
        "organisation": {
          "id": "SJN:Authority:SJN",
          "name": "SJ Nord"
        }
      },
      "geographicalValidity": {
        "serviceJourneys": [
          {
            "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
            "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
          }
        ],
        "pointToPointValidity": {
          "fromPlace": "NSR:StopPlace:659",
          "toPlace": "NSR:StopPlace:507"
        }
      },
      "available": 5
    } ...
  ],
  "unavailableProducts": []
}`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Oslo-Gardermoen with 30DayPass valid for Zones RUT:FareZone:4, RUT:FareZone:3, RUT:FareZone:2" mdxType="ExpandablePanel">
    <pre>
    {`{
    "tripPatternId": "26ed7b1c-65e0-45b3-bf97-d2ad8ebe3772",
    "offers": [
        {
            "id": "de659fde-a858-46ee-89f2-62deb6bdd57b",
            "name": "Enkeltbillett",
            "description": "",
            "price": {
                "amount": "39.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                        "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                    }
                ],
                "zonalValidity": {
                    "zones": [
                        "RUT:FareZone:1"
                    ]
                }
            },
            "preassignedProducts": [
                {
                    "id": "RUT:PreassignedFareProduct:RuterSingleTicket",
                    "version": "RUT:Version:V1",
                    "name": "Enkeltbillett",
                    "description": "",
                    "properties": {
                        "isExchangeable": false,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                    "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                                },
                                "accommodation": []
                            }
                        ],
                        "reservingRequirements": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                    "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                                },
                                "reservingRequirement": "NO_RESERVATIONS_POSSIBLE",
                                "seatMapIsAvailable": false
                            }
                        ],
                        "userProfileId": "RUT:UserProfile:Adult",
                        "userProfileVersion": "RUT:Version:V1",
                        "organisation": {
                            "id": "RUT:Authority:RUT",
                            "name": "Ruter"
                        },
                        "duration": "PT1H",
                        "fareClasses": [
                            "STANDARD_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                            }
                        ],
                        "zonalValidity": {
                            "zones": [
                                "RUT:FareZone:1"
                            ]
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "UNIQUE_IDENTIFIER"
                    ],
                    "maxNumberOfTravellers": 1,
                    "minNumberOfTravellers": 1,
                    "userType": "ADULT"
                }
            ]
        },
        {
            "id": "85b0ac2d-0a73-4b1c-bae6-8b06c29ad6d4",
            "name": "",
            "description": "",
            "price": {
                "amount": "25.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                        "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                    }
                ],
                "zonalValidity": {
                    "zones": [
                        "RUT:FareZone:1"
                    ]
                }
            },
            "preassignedProducts": [
                {
                    "id": "RUT:PreassignedFareProduct:SupplementaryTicket",
                    "version": "RUT:Version:V1",
                    "name": "Billett for ekstra soner",
                    "description": "",
                    "properties": {
                        "isExchangeable": false,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                    "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                                },
                                "accommodation": []
                            }
                        ],
                        "userProfileId": "RUT:UserProfile:Adult",
                        "userProfileVersion": "RUT:Version:V1",
                        "organisation": {
                            "id": "RUT:Authority:RUT",
                            "name": "Ruter"
                        },
                        "duration": "PT1H30M",
                        "fareClasses": [
                            "STANDARD_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                            }
                        ],
                        "zonalValidity": {
                            "zones": [
                                "RUT:FareZone:1"
                            ]
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "UNIQUE_IDENTIFIER"
                    ],
                    "maxNumberOfTravellers": 1,
                    "minNumberOfTravellers": 1,
                    "userType": "ADULT"
                }
            ]
        }
    ]
}`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Oslo-Bergen 4 travellers in 2 compartments" mdxType="ExpandablePanel">
    <pre>
    {`{
    "tripPatternId": "f56da8ad-98d3-4ec8-b3d0-b82fed108158",
    "offers": [
        {
            "id": "68b49f9d-7334-4279-ab61-437d08eca977",
            "name": "Flex",
            "description": "",
            "price": {
                "amount": "279.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                        "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                    }
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "preassignedProducts": [
                {
                    "id": "VYT:PreassignedFareProduct:Flex",
                    "version": "VYT:Version:PFP-Flex-1",
                    "name": "Flex",
                    "description": "- Sete tildeles\\n- Kan endres\\n- Ikke refunderbar",
                    "properties": {
                        "isExchangeable": true,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "accommodation": [
                                    "SEATING"
                                ]
                            }
                        ],
                        "reservingRequirements": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "reservingRequirement": "RESERVATIONS_COMPULSORY",
                                "seatMapIsAvailable": true
                            }
                        ],
                        "userProfileId": "VYT:UserProfile:Adult",
                        "userProfileVersion": "VYT:Version:UP-Adult-1",
                        "organisation": {
                            "id": "VYT:Authority:VYT",
                            "name": "Vy Tog"
                        },
                        "fareClasses": [
                            "STANDARD_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                            }
                        ],
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:548"
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "A",
                        "B",
                        "C",
                        "D"
                    ],
                    "maxNumberOfTravellers": 1,
                    "minNumberOfTravellers": 1,
                    "userType": "ADULT"
                }
            ],
            "available": 2
        },
        {
            "id": "acef5e44-5a02-4136-ab6d-5aaa7d0233e2",
            "name": "Sove",
            "description": "",
            "price": {
                "amount": "999.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                        "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                    }
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "preassignedProducts": [
                {
                    "id": "VYT:PreassignedFareProduct:Sleep",
                    "version": "VYT:Version:PFP-Sleep-1",
                    "name": "Sove",
                    "description": "- Reis i egen sovekupé\\n- En kupé består av to oppredde senger på 75 cm bredde\\n- Kan endres\\n- Ikke refunderbar",
                    "properties": {
                        "isExchangeable": true,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "accommodation": [
                                    "SLEEPER"
                                ]
                            }
                        ],
                        "reservingRequirements": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "reservingRequirement": "RESERVATIONS_COMPULSORY",
                                "seatMapIsAvailable": false
                            }
                        ],
                        "organisation": {
                            "id": "VYT:Authority:VYT",
                            "name": "Vy Tog"
                        },
                        "group": {
                            "id": "VYT:GroupTicket:Sleep",
                            "version": "VYT:Version:GT-Sleep-1",
                            "description": "",
                            "userProfiles": []
                        },
                        "fareClasses": [
                            "PREMIUM_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                            }
                        ],
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:548"
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "B",
                        "D"
                    ],
                    "maxNumberOfTravellers": 2,
                    "minNumberOfTravellers": 2,
                    "userType": "ADULT"
                }
            ],
            "available": 5
        },
        {
            "id": "9e71c4ee-2173-47d9-882d-d1d6d38f736f",
            "name": "Sove",
            "description": "",
            "price": {
                "amount": "999.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                        "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                    }
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "preassignedProducts": [
                {
                    "id": "VYT:PreassignedFareProduct:Sleep",
                    "version": "VYT:Version:PFP-Sleep-1",
                    "name": "Sove",
                    "description": "- Reis i egen sovekupé\\n- En kupé består av to oppredde senger på 75 cm bredde\\n- Kan endres\\n- Ikke refunderbar",
                    "properties": {
                        "isExchangeable": true,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "accommodation": [
                                    "SLEEPER"
                                ]
                            }
                        ],
                        "reservingRequirements": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "reservingRequirement": "RESERVATIONS_COMPULSORY",
                                "seatMapIsAvailable": false
                            }
                        ],
                        "organisation": {
                            "id": "VYT:Authority:VYT",
                            "name": "Vy Tog"
                        },
                        "group": {
                            "id": "VYT:GroupTicket:Sleep",
                            "version": "VYT:Version:GT-Sleep-1",
                            "description": "",
                            "userProfiles": []
                        },
                        "fareClasses": [
                            "PREMIUM_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                            }
                        ],
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:548"
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "A",
                        "C"
                    ],
                    "maxNumberOfTravellers": 2,
                    "minNumberOfTravellers": 2,
                    "userType": "ADULT"
                }
            ],
            "available": 5
        }, ...
    ],
    "unavailableProducts": []
}`}
    </pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "search-by-stop-places"
    }}>{`Search by stop-places`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/stop-places/postOffersV2SearchStopPlaces"
      }}>{`Swagger documentation`}</a></p>
    <p>{`Use the stop-places endpoint if you want to request offers that are not associated with any specific departure.
The offers returned are period tickets or single tickets that are valid between stop places and for a given duration.
Some combinations of stop places may result in offers that cover parts of the distance.
As an example, let's say the "from" stop place is A, and the "to" stop place is C.
There may not be a ticket that is valid from A to C, but tickets that are valid from A to B, and B to C.
In that case you will receive offers that are valid for A to B and B to C.
If you only want offers that cover the whole distance, set `}<inlineCode parentName="p">{`offersForWholeTripOnly`}</inlineCode>{` to true.`}</p>
    <h4 {...{
      "id": "examplerequest-to-searchstop-places"
    }}>{`ExampleRequest to search/stop-places`}</h4>
    <ExpandablePanel title="ExampleRequest to search/stop-places" mdxType="ExpandablePanel">
    <pre>{`
        "travellers": [
            {
                "userType": "ADULT"
            }
        ],
        "travelDate": "2022-02-24T00:00:00+00",
        "from": "NSR:StopPlace:337",
        "to": "NSR:StopPlace:421",
        "offersForWholeTripOnly": false
    `}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "exampleresponse-from-searchstop-places"
    }}>{`ExampleResponse from search/stop-places`}</h4>
    <ExpandablePanel title="ExampleResponse from search/stop-places" mdxType="ExpandablePanel">
    <pre>{`
        {
            "offers": [
                {
                    "id": "c76b31fa-2cd8-4415-aa6c-ff3c1e761a32",
                    "name": "365-dagersbillett",
                    "description": "",
                    "price": {
                        "amount": "100020.00",
                        "currency": "NOK"
                    },
                    "geographicalValidity": {
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:421"
                        }
                    },
                    "preassignedProducts": [
                        {
                            "id": "GOA:PreassignedFareProduct:365days",
                            "version": "GOA:Version:Pfp365days-1",
                            "name": "365-dagersbillett",
                            "description": "- Billetten gjelder for reiser i begge retninger på valgt strekning og for et ubegrenset antall reiser",
                            "properties": {
                                "isExchangeable": true,
                                "isRefundable": false,
                                "userProfileId": "GOA:UserProfile:Adult",
                                "userProfileVersion": "GOA:Version:V1",
                                "organisation": {
                                    "id": "GOA:Authority:GOA",
                                    "name": "Go-Ahead Nordic"
                                },
                                "duration": "PT8760H",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ]
                            },
                            "geographicalValidity": {
                                "pointToPointValidity": {
                                    "fromPlace": "NSR:StopPlace:337",
                                    "toPlace": "NSR:StopPlace:421"
                                }
                            }
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "871c6e3b-7a00-4c5d-8cdf-15e882928593"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                },
                {
                    "id": "5ef7c6a2-17e3-45cc-bcba-ebcbecb097df",
                    "name": "30-dagersbillett",
                    "description": "",
                    "price": {
                        "amount": "10002.00",
                        "currency": "NOK"
                    },
                    "geographicalValidity": {
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:421"
                        }
                    },
                    "preassignedProducts": [
                        {
                            "id": "GOA:PreassignedFareProduct:30days",
                            "version": "GOA:Version:Pfp30days-1",
                            "name": "30-dagersbillett",
                            "description": "- Billetten gjelder for reiser i begge retninger på valgt strekning og for et ubegrenset antall reiser",
                            "properties": {
                                "isExchangeable": true,
                                "isRefundable": false,
                                "userProfileId": "GOA:UserProfile:Adult",
                                "userProfileVersion": "GOA:Version:V1",
                                "organisation": {
                                    "id": "GOA:Authority:GOA",
                                    "name": "Go-Ahead Nordic"
                                },
                                "duration": "PT720H",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ]
                            },
                            "geographicalValidity": {
                                "pointToPointValidity": {
                                    "fromPlace": "NSR:StopPlace:337",
                                    "toPlace": "NSR:StopPlace:421"
                                }
                            }
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "871c6e3b-7a00-4c5d-8cdf-15e882928593"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                }, ...
            ]
        }
        `}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "search-by-authority"
    }}>{`Search by authority`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/authority/postOffersV2SearchAuthority"
      }}>{`Swagger documentation`}</a></p>
    <p>{`Use the `}<inlineCode parentName="p">{`search/authority`}</inlineCode>{` endpoint if you want to get offers for products with a static geographical validity, using only a traveller and authority as input.
Only constraints that will have an affect are Purchase Window, Authority, Distribution Channel and User Profile, everything else will be ignored when evaluating the product definition.`}</p>
    <h4 {...{
      "id": "examplerequests-to-searchauthority"
    }}>{`ExampleRequests to search/authority`}</h4>
    <ExpandablePanel title="ExampleRequest to search/authority" mdxType="ExpandablePanel">
    <pre>{`
    {
        "travelDate": "2022-10-15T03:00:00.000Z",
        "travellers": [
            {
                "userType": "ADULT"
            }
        ],
        "authorityId": "ATB:Authority:2"
    }
    `}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "exampleresponses-from-searchauthority"
    }}>{`ExampleResponses from search/authority`}</h4>
    <ExpandablePanel title="ExampleResponse from search/authority" mdxType="ExpandablePanel">
    <pre>{`
        {
            "offers": [
                {
                    "id": "4b2fe5c8-eb81-4015-8a3a-0ed32861e4d2",
                    "name": "Natt 2",
                    "description": "Natt 2",
                    "price": {
                        "amount": "150.00",
                        "currency": "NOK"
                    },
                    "availableFulfillmentMethods": [
                        {
                            "id": "ENT:FulfilmentMethod:IdBasert",
                            "version": "ENT:Version:FM-IdBasert-1"
                        }
                    ],
                    "geographicalValidity": {},
                    "preassignedProducts": [
                        {
                            "id": "ATB:PreassignedFareProduct:877baeff",
                            "version": "ATB:Version:c463bb9c-7e63-4c84-a07b-837dc264694a",
                            "name": "Natt 2",
                            "description": "Natt 2",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileSummary": {
                                    "id": "ATB:UserProfile:8ee842e3",
                                    "version": "ATB:Version:230ef468-95a1-461a-b109-104ac046cb0d",
                                    "name": "Voksen",
                                    "userType": "ADULT"
                                },
                                "organisation": {
                                    "id": "ATB:Authority:2",
                                    "name": "AtB"
                                },
                                "duration": "PT1H30M",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ],
                                "durationType": "SINGLE_TRIP"
                            },
                            "geographicalValidity": {}
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "3ece9584-4e03-4536-90ce-0037893b5e88"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                },
                {
                    "id": "db2d4e67-bd0c-40d6-afc0-4e016566809b",
                    "name": "Natt 3",
                    "description": "Natt 3",
                    "price": {
                        "amount": "200.00",
                        "currency": "NOK"
                    },
                    "availableFulfillmentMethods": [
                        {
                            "id": "ENT:FulfilmentMethod:IdBasert",
                            "version": "ENT:Version:FM-IdBasert-1"
                        }
                    ],
                    "geographicalValidity": {},
                    "preassignedProducts": [
                        {
                            "id": "ATB:PreassignedFareProduct:e317a807",
                            "version": "ATB:Version:ffdc03c6-2003-429f-a0bc-b8ddaa6b8f9d",
                            "name": "Natt 3",
                            "description": "Natt 3",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileSummary": {
                                    "id": "ATB:UserProfile:8ee842e3",
                                    "version": "ATB:Version:230ef468-95a1-461a-b109-104ac046cb0d",
                                    "name": "Voksen",
                                    "userType": "ADULT"
                                },
                                "organisation": {
                                    "id": "ATB:Authority:2",
                                    "name": "AtB"
                                },
                                "duration": "PT1H30M",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ],
                                "durationType": "SINGLE_TRIP"
                            },
                            "geographicalValidity": {}
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "3ece9584-4e03-4536-90ce-0037893b5e88"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                },
                {
                    "id": "cbe79d7e-99b5-4693-978e-e37ac21a2f0f",
                    "name": "Natt 1",
                    "description": "Natt 1",
                    "price": {
                        "amount": "100.00",
                        "currency": "NOK"
                    },
                    "availableFulfillmentMethods": [
                        {
                            "id": "ENT:FulfilmentMethod:IdBasert",
                            "version": "ENT:Version:FM-IdBasert-1"
                        }
                    ],
                    "geographicalValidity": {},
                    "preassignedProducts": [
                        {
                            "id": "ATB:PreassignedFareProduct:8f351521",
                            "version": "ATB:Version:fecb1f30-5b6a-473c-9987-d8ff1b649560",
                            "name": "Natt 1",
                            "description": "Natt 1",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileSummary": {
                                    "id": "ATB:UserProfile:8ee842e3",
                                    "version": "ATB:Version:230ef468-95a1-461a-b109-104ac046cb0d",
                                    "name": "Voksen",
                                    "userType": "ADULT"
                                },
                                "organisation": {
                                    "id": "ATB:Authority:2",
                                    "name": "AtB"
                                },
                                "duration": "PT1H30M",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ],
                                "durationType": "SINGLE_TRIP"
                            },
                            "geographicalValidity": {}
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "3ece9584-4e03-4536-90ce-0037893b5e88"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                }
            ],
            "explainInfo": {
                "explainNodes": [
                    {
                        "id": "ATB:SalesPackage:55246449",
                        "version": "ATB:Version:35e3b7f3-5158-45ee-9488-9dbc49ff95fe",
                        "reason": "noValidVersion"
                    }
                ]
            }
        }
        `}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "search-by-zones"
    }}>{`Search by zones`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br />{`
`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/zones/postOffersV2SearchZones"
      }}>{`Swagger documentation`}</a></p>
    <p>{`Use the `}<inlineCode parentName="p">{`search/zones`}</inlineCode>{` endpoint if you want to get offers for products that have validity based on zones specified.
As with the `}<inlineCode parentName="p">{`search/stop-places`}</inlineCode>{` endpoint, the `}<inlineCode parentName="p">{`search/zones`}</inlineCode>{` endpoint responds with products that are not limited to a specific departure.
From `}<inlineCode parentName="p">{`search/zones`}</inlineCode>{` you will receive single tickets and period tickets that are valid in the zones specified and for the duration specified in the period ticket.`}</p>
    <h4 {...{
      "id": "examplerequests-to-searchzones"
    }}>{`ExampleRequests to search/zones`}</h4>
    <ExpandablePanel title="ExampleRequest to search/zones without existingTicket specified" mdxType="ExpandablePanel">
    <pre>{`
    {
        "from": "RUT:FareZone:4",
        "to": "RUT:FareZone:3",
        "travelDate": "2022-03-20T11:39:56Z",
        "travellers": [
            {
                "userType": "ADULT"
            }
        ]
    }
    `}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="ExampleRequest to search/zones with existingTicket specified" mdxType="ExpandablePanel">
    <pre>{`
    {
        "from": "RUT:FareZone:4",
        "to": "RUT:FareZone:3",
        "travelDate": "2022-03-20T11:39:56Z",
        "travellers": [
            {
                "userType": "ADULT"
            }
        ],
        "existingTicket": {
            "id": "RUT:PreassignedFareProduct:Ruter24Hours",
            "zones": [
                "RUT:FareZone:4"
            ]
        }
    }
    `}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "exampleresponses-from-searchzones"
    }}>{`ExampleResponses from search/zones`}</h4>
    <ExpandablePanel title="ExampleResponse from search/zones without existingTicket specified" mdxType="ExpandablePanel">
    <pre>{`
        {
        "offers": [
            {
                "id": "c689eaea-7fca-40ff-b5f0-2d177e0ee4e7",
                "name": "24-timersbillett",
                "description": "",
                "price": {
                    "amount": "192.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:FareZone:4",
                            "RUT:FareZone:3"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:Ruter24Hours",
                        "version": "RUT:Version:V1",
                        "name": "24-timersbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": false,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT24H",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:FareZone:4",
                                    "RUT:FareZone:3"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            },
            {
                "id": "dabdd6a1-385a-4ed6-9925-3ee3c06f0a1f",
                "name": "30-dagersbillett",
                "description": "",
                "price": {
                    "amount": "1443.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:FareZone:4",
                            "RUT:FareZone:3"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:Ruter30Days",
                        "version": "RUT:Version:V1",
                        "name": "30-dagersbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": true,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT720H",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:FareZone:4",
                                    "RUT:FareZone:3"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            },
            {
                "id": "df2065db-c27b-4834-bca7-a9c42de858df",
                "name": "Enkeltbillett",
                "description": "",
                "price": {
                    "amount": "64.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:FareZone:4",
                            "RUT:FareZone:3"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:RuterSingleTicket",
                        "version": "RUT:Version:V1",
                        "name": "Enkeltbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": false,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT1H30M",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:FareZone:4",
                                    "RUT:FareZone:3"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            },
            {
                "id": "da7c59d5-97d3-49b6-bd8d-b619147264bc",
                "name": "365-dagersbillett",
                "description": "",
                "price": {
                    "amount": "14430.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:FareZone:4",
                            "RUT:FareZone:3"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:Ruter365Days",
                        "version": "RUT:Version:V1",
                        "name": "365-dagersbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": true,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT8760H",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:FareZone:4",
                                    "RUT:FareZone:3"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            },
            {
                "id": "ac943960-7166-4e40-a1d3-f5e133f1a482",
                "name": "7-dagersbillett",
                "description": "",
                "price": {
                    "amount": "564.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:FareZone:4",
                            "RUT:FareZone:3"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:Ruter7Days",
                        "version": "RUT:Version:V1",
                        "name": "7-dagersbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": true,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT168H",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:FareZone:4",
                                    "RUT:FareZone:3"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            }
        ]
    }
        `}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="ExampleResponse from search/zones with existingTicket specified" mdxType="ExpandablePanel">
    <pre>{`
        {
            "offers": [
                {
                    "id": "e648a768-ddc9-494f-871d-2b031a19c0fe",
                    "name": "Enkeltbillett",
                    "description": "",
                    "price": {
                        "amount": "39.00",
                        "currency": "NOK"
                    },
                    "geographicalValidity": {
                        "zonalValidity": {
                            "zones": [
                                "RUT:FareZone:3"
                            ]
                        }
                    },
                    "preassignedProducts": [
                        {
                            "id": "RUT:PreassignedFareProduct:RuterSingleTicket",
                            "version": "RUT:Version:V1",
                            "name": "Enkeltbillett",
                            "description": "",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileId": "RUT:UserProfile:Adult",
                                "userProfileVersion": "RUT:Version:V1",
                                "organisation": {
                                    "id": "RUT:Authority:RUT",
                                    "name": "Ruter"
                                },
                                "duration": "PT1H",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ]
                            },
                            "geographicalValidity": {
                                "zonalValidity": {
                                    "zones": [
                                        "RUT:FareZone:3"
                                    ]
                                }
                            }
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "12dccdb3-b8cf-4333-b569-243349fa6913"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                },
                {
                    "id": "408c8126-3418-4090-8a1c-417478d66b1c",
                    "name": "",
                    "description": "",
                    "price": {
                        "amount": "25.00",
                        "currency": "NOK"
                    },
                    "geographicalValidity": {
                        "zonalValidity": {
                            "zones": [
                                "RUT:FareZone:3"
                            ]
                        }
                    },
                    "preassignedProducts": [
                        {
                            "id": "RUT:PreassignedFareProduct:SupplementaryTicket",
                            "version": "RUT:Version:V1",
                            "name": "Billett for ekstra soner",
                            "description": "",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileId": "RUT:UserProfile:Adult",
                                "userProfileVersion": "RUT:Version:V1",
                                "organisation": {
                                    "id": "RUT:Authority:RUT",
                                    "name": "Ruter"
                                },
                                "duration": "PT1H30M",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ]
                            },
                            "geographicalValidity": {
                                "zonalValidity": {
                                    "zones": [
                                        "RUT:FareZone:3"
                                    ]
                                }
                            }
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "12dccdb3-b8cf-4333-b569-243349fa6913"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                }
            ]
        }
        `}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "search-for-personalised-offers"
    }}>{`Search for personalised offers`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br /></p>
    <p>{`Travellers might have certain benefits or entitlements that can be used to get discounted offers.
This can be different kinds of campaigns the customer is part of, personalisation programs or other types of benefits.`}</p>
    <h4 {...{
      "id": "campaign-codes"
    }}>{`Campaign codes`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br /></p>
    <p>{`The campaign codes are set on the traveller object when searching for offers.
If the code gives access to an entitlement that is valid for the offer, it will be returned with the discounted price.`}</p>
    <ExpandablePanel title="ExampleRequest to search/zones with campaign codes" mdxType="ExpandablePanel">
    <pre>{`
    {
        ...
        "travellers": [
            {
                ...
                "campaignCodes": [
                    "MYCODE"
                ]
            }
        ]
    }
    `}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "personalisation-programs"
    }}>{`Personalisation programs`}</h4>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br /></p>
    <p>{`For travellers that are part of a personalisation program, the `}<inlineCode parentName="p">{`search`}</inlineCode>{` endpoints can be used to get discounted offers when the customer is identified by `}<inlineCode parentName="p">{`customerId`}</inlineCode>{`.
The `}<inlineCode parentName="p">{`customerId`}</inlineCode>{` points to the `}<inlineCode parentName="p">{`customerNumber`}</inlineCode>{` of the Entur-customer that is enrolled in a program.`}</p>
    <ExpandablePanel title="ExampleRequest to search/zones with identified customer" mdxType="ExpandablePanel">
    <pre>{`
    {
        ...
        "travellers": [
            {
                ...
                "customerId": "1234567"
            }
        ]
    }
    `}</pre>
    </ExpandablePanel>
    <p>{`If buying an offer will result in the customer gaining points in a personalisation program, it will be included in `}<inlineCode parentName="p">{`eligibleForAccrualInPrograms`}</inlineCode>{`.`}</p>
    <p>{`If a program appears in `}<inlineCode parentName="p">{`availablePrograms`}</inlineCode>{`, the customer may be added to the program in order to be eligible for accrual.
The caller must themselves implement the logic to add customers to personalisation programs, using the `}<a parentName="p" {...{
        "href": "/pages-personalisation-docs-personalisation"
      }}>{`Personalisation API`}</a>{`.`}</p>
    <ExpandablePanel title="ExampleResponse from search/zones with offer eligible for accrual" mdxType="ExpandablePanel">
    <pre>{`{
    "offers": [
        {
            "id": "df2065db-ca7b-4834-bcb7-a9d42de858df",
            "name": "Enkeltbillett",
            "description": "",
            "price": {
                "amount": "32.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "zonalValidity": {
                    "zones": [
                        "ENT:FareZone:1",
                    ]
                }
            },
            "preassignedProducts": [
                {
                    "id": "ENT:PreassignedFareProduct:SingleTicket",
                    "version": "ENT:Version:V1",
                    "name": "Enkeltbillett",
                    ...
            ],
            "travellerMapping": [
                ...
            ],
            "eligibleForAccrualInPrograms": [
                {
                    "id": "9",
                    "reference": "entur_bonus",
                    "name": "Entur Bonus Program",
                    "infoLink": "https://entur.no/bonus",
                }
            ],
            "availablePrograms": [
                {
                    "id": "9",
                    "reference": "entur_bonus",
                    "name": "Entur Bonus Program",
                    "infoLink": "https://entur.no/bonus",
                }
            ]
        }
    ]
}`}</pre>
    </ExpandablePanel>
    <p>{`The id of the program can in turn be used to get more information about the program or check the customers status through the `}<a parentName="p" {...{
        "href": "/pages-personalisation-docs-personalisation"
      }}>{`Personalisation API`}</a>{`.`}</p>
    <h3 {...{
      "id": "personnel-tickets"
    }}>{`Personnel tickets`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br /></p>
    <p>{`Some customers have an entitlement that gives them offers with greatly reduced price.
For personnel tickets, the `}<inlineCode parentName="p">{`EntitlementProduct`}</inlineCode>{` needs to be supplied directly in the Offers request, in addition to the `}<inlineCode parentName="p">{`customerId`}</inlineCode>{`.`}</p>
    <ExpandablePanel title="ExampleRequest to search/zones with personnel ticket entitlement" mdxType="ExpandablePanel">
      <pre>{`{
    ...
    "travellers": [
        {
            ...
            "customerId": "1234567",
            "productIds": [
                "ENT:EntitlementProduct:PersonnelTicket"
            ]
        }
    ]
}
`}</pre>
    </ExpandablePanel>
    <p>{`When reserving the offer in the `}<a parentName="p" {...{
        "href": "/pages-sales-reserve-offers-api"
      }}>{`/v1/reserve-offers`}</a>{` Sales API, the `}<inlineCode parentName="p">{`EntitlementProduct`}</inlineCode>{` needs to be supplied in the request as well.`}</p>
    <ExpandablePanel title="ExampleRequest to /v1/reserve-offers with personnel ticket entitlement" mdxType="ExpandablePanel">
    <pre>{`{
    "offerConfigurations": [
        {
            "offerId": "df2065db-ca7b-4834-bcb7-a9d42de858df",
            "customers": [
                {
                    "customerId": "1234567",
                    "entitlements": [
                        {
                            "contractId": "12931728",
                            "entitlementProductRef": {
                                "id": "ENT:EntitlementProduct:PersonnelTicket"
                            }
                         }
                    ]
                }
            ]
        }
    ]
}
`}</pre>
    </ExpandablePanel>
    <h3 {...{
      "id": "discounted-offers"
    }}>{`Discounted offers`}</h3>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br /></p>
    <p>{`Offers that are discounted as a result of an applied entitlement or benefit, will contain information about the discount in the response.
The discount summary is located under `}<inlineCode parentName="p">{`offers[].preassignedProducts[].discountRight`}</inlineCode>{` in the Offers response.`}</p>
    <ExpandablePanel title="ExampleResponse from search/zones with discounted offer" mdxType="ExpandablePanel">
    <pre>{`{
    "offers": [
        {
            "id": "df2065db-ca7b-4834-bcb7-a9d42de858df",
            "name": "Enkeltbillett",
            "description": "",
            "price": {
                "amount": "12.00",
                "currency": "NOK"
            },
            "preassignedProducts": [
                {
                    "id": "ENT:PreassignedFareProduct:SingleTicket",
                    "version": "ENT:Version:V1",
                    "name": "Enkeltbillett",
                    ...,
                    "discountRight": {
                        "id": "ENT:SaleDiscountRight:SomeDiscount",
                        "version": "ENT:Version:V1",
                        "price": {
                            "amount": "20.00",
                            "currency": "NOK"
                        },
                        "name": "Some Discount",
                        "description": "Some discount",
                        "originatingFromProductId": "ENT:EntitlementProduct:PersonnelTicket"
                    }
            ],
            "travellerMapping": [
                ...
            ],
        }
    ]
}`}</pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "offline-clients"
    }}>{`Offline clients`}</h2>
    <p><a parentName="p" {...{
        "href": "#table-of-contents"
      }}>{`(back to Table of contents)`}</a>{` `}<br /></p>
    <p>{`For clients that have poor or no access to the internet, we offer a solution where the client can download a set of data files that can be used to generate offers offline. Access to this functionality is given on request.`}</p>
    <p>{`In essence, there are two different approaches, both sharing the same limitations.
All methods return `}<inlineCode parentName="p">{`SearchResponseFull`}</inlineCode>{`, which is more detailed than the regular search endpoints so that the client may build their own orders.
Offers created this way `}<strong parentName="p">{`cannot`}</strong>{` be used through the regular sales flow at Entur (`}<a parentName="p" {...{
        "href": "/pages-sales-guides#reserve-offer"
      }}>{`/reserve-offers`}</a>{`).`}</p>
    <h3 {...{
      "id": "offline-search"
    }}>{`Offline search`}</h3>
    <p>{`If the client wishes to get offers for specific trips, zones, stopPlaces etc, they can use the offline versions of the `}<a parentName="p" {...{
        "href": "#search-overview"
      }}>{`search endpoints`}</a>{`. This approach gives the client full control over what they wish to request.
The offline versions works exactly like their online counterparts, except for the limitations outlined above.
All endpoints are also found in the `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/offline"
      }}>{`api spec`}</a></p>
    <ul>
      <li parentName="ul">{`Instead of `}<inlineCode parentName="li">{`/offers/v2/search/trip-pattern`}</inlineCode>{`, use `}<inlineCode parentName="li">{`/offers/v2/offline-cache/trip-pattern`}</inlineCode></li>
      <li parentName="ul">{`Instead of `}<inlineCode parentName="li">{`/offers/v2/search/zones`}</inlineCode>{`, use `}<inlineCode parentName="li">{`/offers/v2/offline-cache/zones`}</inlineCode></li>
      <li parentName="ul">{`Instead of `}<inlineCode parentName="li">{`/offers/v2/search/stop-places`}</inlineCode>{`, use `}<inlineCode parentName="li">{`/offers/v2/offline-cache/stop-places`}</inlineCode></li>
      <li parentName="ul">{`Instead of `}<inlineCode parentName="li">{`/offers/v2/search/authority`}</inlineCode>{`, use `}<inlineCode parentName="li">{`/offers/v2/offline-cache/authority`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "servicejourney-with-stopplaces"
    }}>{`ServiceJourney with StopPlaces`}</h3>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/offline/post-offers-v2-offline-cache-sj-stops"
      }}>{`Swagger documentation`}</a></p>
    <p>{`Generates offers for all subsequent pairs of stop places on the given ServiceJourney, for UserTypes `}<inlineCode parentName="p">{`ADULT`}</inlineCode>{`, `}<inlineCode parentName="p">{`CHILD`}</inlineCode>{`, `}<inlineCode parentName="p">{`INFANT`}</inlineCode>{`, `}<inlineCode parentName="p">{`SENIOR`}</inlineCode>{`, `}<inlineCode parentName="p">{`YOUTH`}</inlineCode>{`, `}<inlineCode parentName="p">{`STUDENT`}</inlineCode>{` and `}<inlineCode parentName="p">{`MILITARY`}</inlineCode>{`.
The products one can expect are restricted to single tickets without `}<a parentName="p" {...{
        "href": "/pages-inventory-intro"
      }}>{`quotas`}</a>{`, with no `}<a parentName="p" {...{
        "href": "/pages-seating-intro"
      }}>{`capacity requirements`}</a>{`, no `}<a parentName="p" {...{
        "href": "#search-for-personalised-offers"
      }}>{`personalisation`}</a>{` and no `}<a parentName="p" {...{
        "href": "/pages-recommendations-guide"
      }}>{`recommendations`}</a>{`.
In addition, only distance based products are supported. Do NOT use for zone based pricing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      